import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image'
import PlaneIcon from 'assets/icons/plane_icon.svg';
import {Link} from "gatsby";

const StyledLink = styled(Link)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 100%;
  left: 0;
  transition: opacity .2s, transform 0s;
  background: #000;
  transform: translateY(0%);
  opacity: 0;
`

const StyledWrapper = styled(BackgroundImage)`
  height: 315px;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem;
  z-index: 1;

  &:hover {
    ${StyledLink} {
      transform: translateY(-100%);
      opacity: 0.65;
    }
  }

  ${({theme}) => theme.mq.s} {
    height: 290px;
  }

  ${({theme}) => theme.mq.xs} {
    height: 255px;
  }

  ${({theme}) => theme.mq.xxs} {
    height: 230px;
  }
`;

const StyledName = styled.h3`
  text-transform: uppercase;
  position: absolute;
  font-size: 1.35rem;
  letter-spacing: 2px;
  font-weight: 500;
  top: 80%;
  left: 1.5rem;
  display: inline-block;
  padding: 1rem;
  background: ${({theme}) => theme.color.grayGreen};
  transition: .3s ease;
  opacity: 1;
  transform: translate(0px, 0px);

  ${StyledWrapper}:hover & {
    opacity: 0;
    transform: translate(-200px, 10px);
  }
`;

const StyledNameHover = styled.h3`
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: 2px;
  width: 90%;
  top: 10%;
  left: 2.5%;
  color: ${({theme}) => theme.color.white};
  font-weight: 500;
  padding: 5px;
  text-align: center;
  transition: .3s ease;
  opacity: 0;
  transform: translate(20px, -100px);

  ${StyledWrapper}:hover & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

const StyledDimensions = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const StyledTable = styled.div`
  text-decoration: none;
  color: ${({theme}) => theme.color.white};
  display: grid;
  grid-column-gap: 3.5rem;
  grid-row-gap: 1rem;
  text-transform: uppercase;
  justify-self: left;
  justify-content: start;
  grid-template-columns: auto auto;
  font-weight: 500;
  font-size: 1.4rem;
  padding: 1.5rem;
  transition: .35s ease-out 0s;

  ${StyledWrapper}:hover & {
    transition: .3s ease-out .15s;
  }
`;


const StyledKey = styled.span`
  transition: .35s ease-out;
  transition-delay: ${({i}) => `${i * 0.05}s`};
  opacity: 0;
  transform: translateX(-200px);
  text-align: right;

  ${StyledWrapper}:hover & {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const StyledValue = styled.span`
  transition: .35s ease-out;
  transition-delay: ${({i}) => `${i * 0.05}s`};
  opacity: 0;
  transform: translateX(200px);
  text-align: left;

  ${StyledWrapper}:hover & {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const StyledPlaneIcon = styled(PlaneIcon)`
  position: absolute;
  height: 45px;
  right: 0;
  bottom: 0;
`

const dimension = {
  chest: {
    male: 'chest',
    female: 'bust',
  }
}


const ModelTile = ({
                     name, fluid, slug, sizes, international, gender,
                   }) => (
  <StyledWrapper style={{filter: 'grayscale(100%)'}} fluid={fluid}>
    <StyledLink to={`/models/${slug}`}/>
    <StyledName>{name}</StyledName>
    <StyledNameHover as={Link} to={`/models/${slug}`}>{name}</StyledNameHover>
    {international && <StyledPlaneIcon/>}
    <StyledDimensions>
    <StyledTable as={Link} to={`/models/${slug}`}>
        {!!sizes.height && (
          <>
            <StyledKey i={0}>height</StyledKey>
            <StyledValue i={4}>{sizes.height}</StyledValue>
          </>
        )}
        {!!sizes.bust && (
          <>
            <StyledKey i={1}>{gender === 'male' ? 'chest' : 'bust'}</StyledKey>
            <StyledValue i={3}>{sizes.bust}</StyledValue>
          </>
        )}
        {!!sizes.waist && (
          <>
            <StyledKey i={2}>waist</StyledKey>
            <StyledValue i={2}>{sizes.waist}</StyledValue>
          </>
        )}
        {!!sizes.hips && (
          <>
            <StyledKey i={3}>hips</StyledKey>
            <StyledValue i={1}>{sizes.hips}</StyledValue>
          </>
        )}
    </StyledTable></StyledDimensions>

  </StyledWrapper>
)

export default ModelTile;
